// 部門控制器
import { useRuntimeConfig } from '#app'
const { useCustomFetch } = useFetchBase()

// request(REQ) Api  --------------------------------
const base = '/api/auth'
const modules = '/departments'
const path = base + modules

const getDepartments = path // 獲取部門列表
const getDeptDetail = path + '/info' // 獲取部門詳細信息(包含座席狀態)
const addDept = path // 添加部門
const editDept = path + '/{0}' // 更新部門
const deleteDept = path + '/{0}?departmentId={1}' // 刪除部門
const searchDept = path + '/info/q' // 搜尋部門

// request(REQ) Function  --------------------------------

/**
 * 獲取部門列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getDepartmentsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getDepartments + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取部門詳細信息
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const getDeptDetailFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getDeptDetail + '?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 添加部門
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const addDeptFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addDept, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 添加部門
 * @param {String} deptId 部門id
 * @param {Object} formData 表單提交
 * @param {Object} callback 回調函數
 * @returns
 */
const editDeptFn = (deptId, formData, callback) => { // 添加部門
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(editDept, deptId)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除部門
 * @param {String} deptId 部門id
 * @param {String} moveDeptId 刪除後轉移部門id
 * @param {Object} callback 回調函數
 * @returns
 */
const deleteDeptFn = (deptId, moveDeptId, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(deleteDept, deptId, moveDeptId)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 搜尋部門
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
const searchDeptFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + searchDept + '?name=' + params.name + '&pageNum=' + params.pageNum + '&pageSize=' + params.pageSize, {
    method: 'GET',
    onResponse: callback
  })
}

export {
  getDepartmentsFn,
  getDeptDetailFn,
  addDeptFn,
  editDeptFn,
  deleteDeptFn,
  searchDeptFn
}
